/* Home page */

import React from 'react';
import { withPrefix, graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { StaticImage } from 'gatsby-plugin-image';
import BasicSection from '../components/BasicSection';
import Banner from '../components/Banner';
import Footer from '../components/FooterSimple';
import { localizer } from '../util/localizer';
import logo from '../images/logo.png';

export default function Home(props) {
  const ctx = props.pageContext;
  // console.log(`Current ctx at ${new Date().toLocaleTimeString()}`, ctx);
  const sitedata = props.data.siteYaml;
  const localized = localizer(ctx);

  return (
    <div id="page">
      <Helmet htmlAttributes={{lang: ctx.locale}}>
        <meta charSet="utf-8" />
        <title>{sitedata.sitename}</title>
        <link rel="canonical" href={sitedata.siteurl} />
        <meta name="description" content={sitedata.metaDescription}></meta>
        <script src={withPrefix('optanonwrapper.js')} />
      </Helmet>
      <div id="layout-root">
          <Link to={localized('/')} title={sitedata.sitename}>
            <img src={logo} alt={sitedata.siteurl} className="banner-logo img-responsive" />
          </Link>
          <Banner title={sitedata.title} subtitle={sitedata.subtitle} />
        <main>{sitedata.sections.map((section, index) => <BasicSection key={section.id} src={section} />)}</main>
        <div className="-fluid">
          <StaticImage src='../images/above-footer.jpg' alt="People exercising" className="mx-auto" />
        </div>
        <Footer sitename={sitedata.sitename} src={sitedata.footer} localizerfn={localized} />
      </div>
    </div>
  );
};

export const query = graphql`
  query siteLocaleQ($locale: String, $variation: String) {
    siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      locale
      variation
      sitename
      siteurl
      metaDescription
      subtitle
      title
      sections {
        id
        theme
        title
        headerclass
        text
      }
      footer {
        text
        links {
          label
          to
        }
      }
      errorpage {
        header
        subheader
        appError
      }
    }
  }`;

/*
              <StaticImage src='../images/logo.png' alt={sitedata.sitename} layout="constrained" />
*/
